import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Goblet Jump Squats 3×12`}</p>
    <p>{`DB Stiff Leg Deadlifts 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 280ft Sled Push (down & back x2, Sled +90/45`}{`#`}{`)`}</p>
    <p>{`3 Rounds of,`}</p>
    <p>{`20-Box Jumps (24/20″)`}</p>
    <p>{`20-Wall Balls (20/14`}{`#`}{`)`}</p>
    <p>{`20-Overhead Stationary Lunges (Holding 20/14`}{`#`}{` ball OH, 10/leg)`}</p>
    <p>{`Cash Out: 280ft Sled Push`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      